#header-container {
  #nav-container {
    transform: translateY(-100%);
    transition: transform .5s cubic-bezier(.4,0,.2,1);
    background-color: #202020;
    border-bottom: 1px solid #393a3b;
  }

  &:hover {
    #nav-container {
      transform: translateY(0);
    }
  }
}