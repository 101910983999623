.projects-list {
  ul {
    list-style-type: none;
    li {
      background-color: #242526;
    }
  }
  a {
    color: #e4e6eb;
    &:hover {
      text-decoration: none;
    }
  }
}

#main-content {
  .card-body {
    background-color: #0e0e10;
    color: blue;
  }
  .cursor-pointer {
    cursor: pointer;
  }
}

.alert-modal{
  background: #fff;
  color: #000;
  font-size: large;
}