
:global {
  .single-media {
    a {
      color: #ffffff;
    }

    .card {
      border: 0;

      .card-header {
        border-width: 3px;
        border-style: solid;
        background-color: transparent;
        cursor: pointer;
        height: 400px;
        position: relative;

        img {
          //object-position: center;
          //object-fit: cover;
          //width: 100%;
          //height: 100%;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          //display: inline-block;
          //vertical-align: middle;
        }

        .media-opacity {
          opacity: 0.4;
        }

        .media-bg-container {
          background-size: cover;
          background-position: center;
          height: 400px;
        }
      }

      .card-body {
        background-color: #0e0e10;

        input {
          cursor: pointer;
        }

        label {
          cursor: pointer;
        }

        .btn-media-zoom {

        }
      }
    }
  }

  .media-zoom-modal {
    .modal-content {
      background-color: transparent;
      border: none;
    }
  }

  .report-media {
    .card {
      .card-header {
        border: 1px solid;
      }
    }
  }

  .Kids-media-info-text {
    font-size: 0.8rem !important;
    padding: 0;
  }
  .Image-Sharing-2-media-info-text {
    font-size: 1rem !important;
  }
  .media-info-text {
    font-size: 0.8rem;
    padding: 0;
  }

  .us{border: #ffeb3b 5px solid !important;}
  .watermark-0, .watermark-1, .watermark-2 {position:absolute;z-index:9999;display:block;min-height:50%; min-width:120%;color:yellow;display: none;}
  .bg-text{font-size:100px;transform:rotate(310deg);-webkit-transform:rotate(310deg);padding-top: 30px;}
  .bg-text-Image_Explicit{font-size:65px;transform:rotate(310deg);-webkit-transform:rotate(310deg);padding-top: 20px;margin-right: -150px;margin-left: -50px;}  
  .bg-text-Illustration_Explicit{font-size:60px;transform:rotate(310deg);-webkit-transform:rotate(310deg);padding-top: 0px;margin-right: -185px;margin-left: -65px;}  
  .watermark-Reject {display: inline !important;color:#c36565}
  .watermark-Broken {display: inline !important; color:#000}
  .watermark-Image_Explicit, .watermark-Illustration_Explicit {display: inline !important; color:#e5cfcf}
  .note-Kids {    white-space: pre-line;  }
  .post-header{box-sizing: border-box;padding: 10px;margin: 7px 0px 5px 0px;background: #c5c5c5;border-radius: 3px 3px 0 0;box-shadow: 0 4px 4px -2px rgba(0,0,0,.2);position: relative;font-size: 16px;max-width: 500px;color: #000;}
  .post-header-RaceCar{position: absolute;top: 230px;left: 162px;}
  .bg-black {background-color: #000 !important;}
  .bg-purple{background-color: #7242e3; border: 1px #7242e4 solid;}
  .post-header-IS1_Album{max-width: 100% !important}
  .float-right{float: right;}
  .show{display: block;}
  .hide{display: none;}
  .Love_Audio {height: auto !important;}
  .is1_album_paging{color: #2758c9;}
  .middle-content-green-RaceCar{background: rgb(100, 202, 24);text-align: center;font-size: larger;}
  .middle-content-red-RaceCar{background: rgb(253, 16, 18);text-align: center;font-size: larger;}

  @media (min-width: 1360px) {
    .Kids-media-info-text {
      font-size: 0.8rem !important;
      padding: 0;
    }
    .Image-Sharing-2-media-info-text {
      font-size: 1.2rem !important;
    }
    .RaceCar-media-info-text{
      font-size: 0.7rem !important;
    }
    .Storytelling_Queue2-media-info-text{
      font-size: 0.7rem !important;
    }
    
    .media-info-text {
      font-size: 0.8rem;
      padding: 0;
    }
  }


  .rainbow-text {
    background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
    -webkit-background-clip: text;
    color: transparent;

  }
}